import React, { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Layout } from 'antd'

const { Header, Content, Footer } = Layout

const MainLayout: FC = () => {
  const { pathname } = useLocation()
  console.log(useLocation())
  const showFlag = !['/login', '/register'].includes(pathname)
  return (
    <Layout>
      {showFlag && <Header></Header>}
      <Content>
        <Outlet />
      </Content>
      {showFlag && <Footer></Footer>}
    </Layout>
  )
}
export default MainLayout
