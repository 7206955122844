import axios from 'axios'
import { message } from 'antd'
const instance = axios.create({
  // baseURL: 'http://sxmp.vip',
  timeout: 10 * 1000,
})

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(res => {
  console.log(res, 'sss')
  const resData = (res.data || {}) as ResType
  const { errno, data = {}, msg } = resData
  return resData as any
  // if (errno !== 0) {
  //   if (msg) {
  //     message.error(msg)
  //   }
  //   throw new Error(msg)
  // }
  // return data as any
})

export default instance

export type ResType = {
  errno: number
  data?: ResDataType
  msg?: string
}

export type ResDataType = {
  [key: string]: any
}
