import React, { FC, useEffect } from 'react'
import style from './index.module.scss'
import { loginService, registerService } from '../../services/user'
const Login: FC = () => {
  const register = () => {
    try {
      loginService({
        password: '222365',
        email: '112@qq.com',
      })
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    register()
  }, [])
  return <div className={style.container}></div>
}

export default Login
