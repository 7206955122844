import axios, { ResDataType } from './ajax'

type RegisterType = {
  username: string
  password: string
  phone: string
  email: string
}
export async function registerService(data: RegisterType): Promise<ResDataType> {
  return (await axios.post('/api/user/register', data)) as ResDataType
}
export async function loginService(data: Partial<RegisterType>): Promise<ResDataType> {
  return (await axios.post('/api/user/login', data)) as ResDataType
}
